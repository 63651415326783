import React from "react";
import { ScrollAndShow } from "../components/scroll-and-show";
import { Layout } from "../components/layout";
import { useCareers } from "../hooks/useCareers";
import { Stories } from "../components/stories";
import { useTranslation, Trans } from "react-i18next";
import { SEO } from "../components/seo";

const CareersPage = () => {
	const [t, i18n] = useTranslation();

	const { careers } = useCareers();

	return (
		<Layout>
			<SEO
				title={`recruit | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description="Give & Giveをモットーにお互いを助け合いながらお客様に尽力します。RUBIAで仕事をする事で自己のレベルアップと夢の実現へ繋がれば幸いです。ポジティブな職場を目指しております。"
				keywords={[
					"ルビア",
					"リクルート",
					"渋谷",
					"スペイン坂",
					"メキシコ料理",
				]}
			/>
			<h1 className="h1">アクセス | RUBIA ルビア / DJ sarasa</h1>
			<div className="careers">
				<ScrollAndShow>
					<div className="careers-text font-lato">CAREERS</div>
				</ScrollAndShow>
				<ScrollAndShow>
					<div className="description">
						{i18n.language === "ja"
							? "RUBIAではチームメンバーが、Give & Giveをモットーにお互いを助け合いながら同一のゴールを目指します。チーム一体となりお客様に尽力するポジティブな職場を目指しております。"
							: 'At RUBIA, team members aim for the same goal while helping each other with the spirit of ""Giving"". We like to invite you to our positive workplace where we work together to serve to our customers.'}
					</div>
				</ScrollAndShow>
				{careers.map((career, index) => (
					<ScrollAndShow key={index}>
						<div className="table-wrapper">
							<div className="position-text font-lato">{career.title}</div>
							{i18n.language === "ja" ? (
								<div className="font-yu">
									<ul>
										<li className="key">雇用形態</li>
										<li className="value">{career.position}</li>
									</ul>
									<ul>
										<li className="key">職種</li>
										<li className="value">{career.role}</li>
									</ul>
									<ul>
										<li className="key">勤務時間</li>
										<li className="value">{career.time}</li>
									</ul>
									<ul>
										<li className="key">休日</li>
										<li className="value">{career.daysOff}</li>
									</ul>
									<ul>
										<li className="key">給与</li>
										<li className="value">{career.salary}</li>
									</ul>
									<ul>
										<li className="key">業務内容</li>
										<li className="value">{career.job}</li>
									</ul>
									<ul>
										<li className="key">
											このような方は
											<br className="pc" />
											大歓迎です
										</li>
										<li className="value preferred">
											{career.preferred.split("\n").map((line, index) => (
												<span key={index}>
													{line}
													<br />
												</span>
											))}
										</li>
									</ul>
								</div>
							) : (
								<div className="font-lato">
									<ul>
										<li className="key">Position</li>
										<li className="value">{career.positionEn}</li>
									</ul>
									<ul>
										<li className="key">Role</li>
										<li className="value">{career.roleEn}</li>
									</ul>
									<ul>
										<li className="key">Time</li>
										<li className="value">{career.timeEn}</li>
									</ul>
									<ul>
										<li className="key">Days off</li>
										<li className="value">{career.daysOffEn}</li>
									</ul>
									<ul>
										<li className="key">Salary</li>
										<li className="value">{career.salaryEn}</li>
									</ul>
									<ul>
										<li className="key">About the Job</li>
										<li className="value">{career.jobEn}</li>
									</ul>
									<ul>
										<li className="key">Such people are especially welcome!</li>
										<li className="value preferred">
											{career.preferredEn.split("\n").map((line, index) => (
												<span key={index}>
													{line}
													<br />
												</span>
											))}
										</li>
									</ul>
								</div>
							)}
						</div>
					</ScrollAndShow>
				))}

				<ScrollAndShow>
					<div className="contact-info-container">
						<div className="contact">
							<div className="tel-text font-yu">お電話でお問い合わせ</div>
							<div className="tel font-lato">
								{i18n.language === "ja" ? (
									<a href="tel:0364165253">03-6416-5253</a>
								) : (
									<a href="tel:0364165253">+813-6416-5253</a>
								)}
							</div>
						</div>

						<div className="divider" />

						<div className="contact">
							<div className="email-text font-yu">メールでお問い合わせ</div>
							<div className="email font-lato">
								<a href="mailto:info@rubia.co.jp">info@rubia.co.jp</a>
							</div>
						</div>
					</div>
				</ScrollAndShow>
			</div>
		</Layout>
	);
};

export default CareersPage;
