import { useState, useEffect } from 'react'
import { request } from '../lib/axisClient'
import { Career } from '../types/career'

export const useCareers = () => {
	const [careers, setCareers] = useState<Career[]>([])

	useEffect(() => {
		fetchCareers()
	}, [])

	const fetchCareers = async () => {
		try {
			const res = await request({
				url: '/careers',
				method: 'GET'
			})
			setCareers(res.contents)
		} catch (e) {
			console.log(e)
		}
	}

	return { careers }
}
